import Vue from 'vue'
import BasicPageCard from '@/shared/components/layouts/BasicPageCard'

Vue.component('admin-page-card', BasicPageCard)

const Admin = {
  name: 'Admin',
  render(h) {
    document.title = 'FI Navigator'
    return h('router-view')
  }
}

export default Admin
